// import React from 'react';
// import './css/QuestionAnswerComponent.css'; // Import the CSS file
// import config from '../../config';
// const QuestionAnswerComponent = ({ question, answer }) => {
//   const isImage = (answer) => {
//     const imageExtensions = ['.png', '.jpg', '.jpeg', '.svg'];
//     return imageExtensions.some((ext) => answer.toLowerCase().endsWith(ext));
//   };

//   return (
//     <div className="question-answer-container">
//       <div className="question">Q:{question}</div>
//       <div className="answer">
//         {isImage(answer) ? (
//           <img src={`${config.imageUrl}${answer}`} alt="Answer" />
//         ) : (
//           <p>A: {answer}</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default QuestionAnswerComponent;


// import React from 'react';
// import './css/QuestionAnswerComponent.css'; // Import the CSS file
// import config from '../../config';
// const QuestionAnswerComponent = ({ question, answer }) => {
//   const isImage = (answer) => {
//     const text =answer?answer:''
//     const imageExtensions = ['.png', '.jpg', '.jpeg', '.svg'];
//     return imageExtensions.some((ext) => text.toLowerCase().endsWith(ext));
//   };

//   const isVideo = (answer) => {
//     const text =answer?answer:''
//     const videoExtensions = ['.mp4', '.webm', '.ogg'];
//     return videoExtensions.some((ext) => text.toLowerCase().endsWith(ext));
//   };

//   return (
//     <div className="question-answer-container">
//       <div className="question">{question?question:''}</div>
//       <div className="answer">
       
//         {isImage(answer) ? (
//           <>
          
//           <img src={`${config.imageUrl}${answer}`} alt="Answer" />
//           </>
//         ) : isVideo(answer) ? (
//           <video className='Question-Answer-Video' controls>
//             <source src={`${config.imageUrl}${answer}`} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         ) : (<>
//         {console.log(answer,"answer...</div>",)}
//           {answer && Array.isArray(answer) && answer.length > 0 ? (
//             answer.map((answer, index) => (
//               <p key={index}>{answer ? answer : ""}</p>
//             ))
//           ) : (
//             <p>{answer?answer:""}</p>
//           )}
//     </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default QuestionAnswerComponent;

//answer display 

// import React from 'react';
// import './css/QuestionAnswerComponent.css'; // Import the CSS file
// import config from '../../config';

// const QuestionAnswerComponent = ({ question, answer }) => {
//   const isImage = (text) => {
//     const imageExtensions = ['.png', '.jpg', '.jpeg', '.svg'];
//     return text && typeof text === 'string' && imageExtensions.some((ext) => text.toLowerCase().endsWith(ext));
//   };

//   const isVideo = (text) => {
//     const videoExtensions = ['.mp4', '.webm', '.ogg'];
//     return text && typeof text === 'string' && videoExtensions.some((ext) => text.toLowerCase().endsWith(ext));
//   };

//   const renderAnswer = (ans) => {
//     // Handle null, undefined, or other falsy values gracefully
//     if (!ans) {
//       return <p>No answer available</p>;
//     }

//     // If the answer is an image
//     if (isImage(ans)) {
//       return <img src={`${config.imageUrl}${ans}`} alt="Answer" />;
//     }

//     // If the answer is a video
//     if (isVideo(ans)) {
//       return (
//         <video className="Question-Answer-Video" controls>
//           <source src={`${config.imageUrl}${ans}`} type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//       );
//     }

//     // If the answer is an object (i.e., JSON) and not null or undefined
//     if (typeof ans === 'object' && !Array.isArray(ans)) {
//       return (
//         <div className="json-answer">
//           {Object.keys(ans).map((key, index) => (
//             <p key={index}>
//               <strong>{key}:</strong>{' '}
//               {Array.isArray(ans[key])
//                 ? ans[key].length === 0
//                   ? 'No data'
//                   : ans[key].join(', ')
//                 : ans[key] || 'No value'}
//             </p>
//           ))}
//         </div>
//       );
//     }

//     // If the answer is an array, iterate and render each item
//     if (Array.isArray(ans)) {
//       return ans.length > 0 ? (
//         ans.map((item, index) => <div key={index}>{renderAnswer(item)}</div>)
//       ) : (
//         <p>No data available</p>
//       );
//     }

//     // For plain text or any other primitive value
//     return <p>{ans || 'No answer available'}</p>;
//   };

//   return (
//     <div className="question-answer-container">
//       <div className="question">{question || 'No question available'}</div>
//       <div className="answer">
//         {console.log(answer,'.........................................')}
//         {renderAnswer(answer)}
//       </div>
//     </div>
//   );
// };

// export default QuestionAnswerComponent;





// import React from 'react';
// import './css/QuestionAnswerComponent.css'; // Import the CSS file
// import config from '../../config';

// const QuestionAnswerComponent = ({ question, answer }) => {
  
//   // Helper to check if a string is JSON-like (starts with '{' or '[')
//   const isJsonString = (str) => {
//     if (typeof str !== 'string') return false;
//     str = str.trim();
//     return str.startsWith('{') || str.startsWith('[');
//   };

//   // Helper to try and parse JSON safely
//   const tryParseJson = (str) => {
//     try {
//       return JSON.parse(str);
//     } catch (e) {
//       return str; // Return the original string if parsing fails
//     }
//   };

//   const isImage = (text) => {
//     const imageExtensions = ['.png', '.jpg', '.jpeg', '.svg'];
//     return text && typeof text === 'string' && imageExtensions.some((ext) => text.toLowerCase().endsWith(ext));
//   };

//   const isVideo = (text) => {
//     const videoExtensions = ['.mp4', '.webm', '.ogg'];
//     return text && typeof text === 'string' && videoExtensions.some((ext) => text.toLowerCase().endsWith(ext));
//   };

//   const renderAnswer = (ans) => {
//     // Handle null, undefined, or other falsy values gracefully
//     if (!ans) {
//       return <p>No answer available</p>;
//     }

//     // Handle "[all above]" special case
//     if (typeof ans === 'string' && ans.trim() === '[all above]') {
//       return <p>All of the above</p>;
//     }

//     // Check if the answer is a string that looks like JSON and parse it
//     if (typeof ans === 'string' && isJsonString(ans)) {
//       ans = tryParseJson(ans);
//     }

//     // If the answer is an image
//     if (isImage(ans)) {
//       return <img src={`${config.imageUrl}${ans}`} alt="Answer" />;
//     }

//     // If the answer is a video
//     if (isVideo(ans)) {
//       return (
//         <video className="Question-Answer-Video" controls>
//           <source src={`${config.imageUrl}${ans}`} type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//       );
//     }

//     // If the answer is an object (i.e., JSON) and not null or undefined
//     if (typeof ans === 'object' && !Array.isArray(ans)) {
//       return (
//         <div className="json-answer">
//           {Object.keys(ans).map((key, index) => (
//             <p key={index}>
//               <strong>{key}:</strong>{' '}
//               {Array.isArray(ans[key])
//                 ? ans[key].length === 0
//                   ? 'No data'
//                   : ans[key].join(', ')
//                 : ans[key] || 'No value'}
//             </p>
//           ))}
//         </div>
//       );
//     }

//     // If the answer is an array, iterate and render each item
//     if (Array.isArray(ans)) {
//       return ans.length > 0 ? (
//         ans.map((item, index) => <div key={index}>{renderAnswer(item)}</div>)
//       ) : (
//         <p>No data available</p>
//       );
//     }

//     // For plain text or any other primitive value
//     return <p>{ans || 'No answer available'}</p>;
//   };

//   return (
//     <div className="question-answer-container">
//       <div className="question">{question || 'No question available'}</div>
//       <div className="answer">
//         {renderAnswer(answer)}
//       </div>
//     </div>
//   );
// };

// export default QuestionAnswerComponent;




import React from 'react';
import './css/QuestionAnswerComponent.css'; // Import the CSS file
import config from '../../config';
import { notification } from 'antd';

const QuestionAnswerComponent = ({ question, answer }) => {
  
  // Helper to check if a string is JSON-like (starts with '{' or '[')
  const isJsonString = (str) => {
    if (typeof str !== 'string') return false;
    str = str.trim();
    return str.startsWith('{') || str.startsWith('[');
  };

  // Helper to try and parse non-standard JSON-like strings like {digital: Yes, images: []}
  const tryParseNonStandardJson = (str) => {
    try {
      // Replace unquoted keys and values like {digital: Yes} -> {"digital": "Yes"}
      str = str
        .replace(/([a-zA-Z0-9_]+)\s*:/g, '"$1":') // Quote keys
        .replace(/:\s*([a-zA-Z0-9_]+)/g, ': "$1"'); // Quote values if not already quoted

      return JSON.parse(str);
    } catch (e) {
      return str; // Return the original string if parsing fails
    }
  };

  const isImage = (text) => {
    const imageExtensions = ['.png', '.jpg', '.jpeg', '.svg'];
    return text && typeof text === 'string' && imageExtensions.some((ext) => text.toLowerCase().endsWith(ext));
  };

  const isVideo = (text) => {
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    return text && typeof text === 'string' && videoExtensions.some((ext) => text.toLowerCase().endsWith(ext));
  };

  // const renderAnswer = (ans) => {
  //   // Handle null, undefined, or other falsy values gracefully
  //   if (!ans) {
  //     return <p>No answer available</p>;
  //   }

  //   // Handle "[all above]" special case
  //   if (typeof ans === 'string' && ans.trim() === '[all above]') {
  //     return <p>All of the above</p>;
  //   }

  //   // Check if the answer is a string that looks like JSON and parse it
  //   if (typeof ans === 'string' && isJsonString(ans)) {
  //     ans = tryParseNonStandardJson(ans);
  //   }

  //   // If the answer is an image
  //   if (isImage(ans)) {
  //     return <img src={`${config.imageUrl}${ans}`} alt="Answer" />;
  //   }

  //   // If the answer is a video
  //   if (isVideo(ans)) {
  //     return (
  //       <video className="Question-Answer-Video" controls>
  //         <source src={`${config.imageUrl}${ans}`} type="video/mp4" />
  //         Your browser does not support the video tag.
  //       </video>
  //     );
  //   }

  //   // If the answer is an object (i.e., JSON) and not null or undefined
  //   if (typeof ans === 'object' && !Array.isArray(ans)) {
  //     return (
  //       <div className="json-answer">
  //         {Object.keys(ans).map((key, index) => (
  //           <p key={index}>
  //             <strong>{key}:</strong>{' '}
  //             {Array.isArray(ans[key])
  //               ? ans[key].length === 0
  //                 ? 'No data'
  //                 : ans[key].join(', ')
  //               : ans[key] || 'No value'}
  //           </p>
  //         ))}
  //       </div>
  //     );
  //   }

  //   // If the answer is an array, iterate and render each item
  //   if (Array.isArray(ans)) {
  //     return ans.length > 0 ? (
  //       ans.map((item, index) => <div key={index}>{renderAnswer(item)}</div>)
  //     ) : (
  //       <p>No data available</p>
  //     );
  //   }

  //   // For plain text or any other primitive value
  //   return <p>{ans || 'No answer available'}</p>;
  // };
  const renderAnswer = (ans) => {
    // Handle null, undefined, or other falsy values gracefully
    if (!ans) {
      return <p>No answer available</p>;
    }
  
    // Handle "[all above]" special case
    if (typeof ans === 'string' && ans.trim() === '[all above]') {
      return <p>All of the above</p>;
    }
  
    // Helper function to check if a string looks like an image path
    // const isImage = (val) => /\.(jpeg|jpg|gif|png|svg)$/.test(val.trim());
    const isImage = (val) => {
      return /\.(jpeg|jpg|gif|png|svg)$/.test(String.prototype.trim.call(val));
    }
    // Check if the answer is a string that looks like JSON and parse it
    if (typeof ans === 'string' && isJsonString(ans)) {
      ans = tryParseNonStandardJson(ans);
    }
  
    // If the answer contains multiple image paths, split and render each image
    // if (typeof ans === 'string' && ans.includes('.png') || ans.includes('.jpg')) {
    //   const imagePaths = ans.replace(/[\[\]]/g, '')
    //     .split(',')
    //     .map((path) => path.trim().replace(/\\/g, '/')); // Normalize the paths
  
    //   return (
    //     <div className="image-gallery">
    //       {imagePaths.map((path, index) => (
    //         <img key={index} src={`${config.imageUrl}${path}`} alt={`Answer ${index + 1}`} style={{width:'45%' ,margin:'0 2.5%'}}/>
    //       ))}
    //     </div>
    //   );
    // }
    if (typeof ans === 'string') {
      if (ans.includes('.png') || ans.includes('.jpg')) {
        const imagePaths = ans.replace(/[\[\]]/g, '')
          .split(',')
          .map((path) => path.trim().replace(/\\/g, '/')); // Normalize the paths
    
        return (
          <div className="image-gallery">
            {imagePaths.map((path, index) => (
              <img key={index} src={`${config.imageUrl}${path}`} alt={`Answer ${index + 1}`} style={{width: '45%', margin: '0 2.5%'}} />
            ))}
          </div>
        );
      }
    } else if (Array.isArray(ans)) {
      // Handle the case where ans might be an array of image paths
      const imagePaths = ans.map((path) => path.trim().replace(/\\/g, '/'));
    
      return (
        <div className="image-gallery">
          {imagePaths.map((path, index) => (
            <img key={index} src={`${config.imageUrl}${path}`} alt={`Answer ${index + 1}`} style={{width: '45%', margin: '0 2.5%'}} />
          ))}
        </div>
      );
    } else {
      // Handle cases where ans is neither a string nor an array
      return null;
    }
    if (typeof ans === "string" && /^(\[#[0-9A-Fa-f]{6}(,\s*\#[0-9A-Fa-f]{6})*\])$/.test(ans)) {



      function getContrastYIQ(hexColor) {
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq >= 128 ? "#000000" : "#FFFFFF"; // Return black or white
      }
    
      const copyToClipboard = (text) => {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            notification("success", `Copied to clipboard: ${text}`);
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
          });
      };
    
      const colorCodes = ans
        .replace(/[\[\]]/g, "") // Remove brackets
        .split(",") // Split by comma
        .map((color) => color.trim()); // Trim any whitespace

      return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {colorCodes.map((colorCode, index) => (
            <div
              key={index}
              style={{
                background: colorCode,
                width: "22%",
                padding: "7px 10px",
                color: getContrastYIQ(colorCode), // Get contrast color
                textAlign: "center",
                // marginBottom: "15px",
                margin:"0 5px 15px",
                boxSizing: "border-box",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={() => copyToClipboard(colorCode)} // Handle click event
            >
              {colorCode} {/* Display the color code text */}
            </div>
          ))}
        </div>
      );
    }
    // If the answer is an image (single path)
    if (isImage(ans)) {
      return <img src={`${config.imageUrl}${ans.replace(/\\/g, '/')}`} alt="Answer" />;
    }
  
    // If the answer is a video
    if (isVideo(ans)) {
      return (
        <video className="Question-Answer-Video" controls>
          <source src={`${config.imageUrl}${ans}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
  
    // If the answer is an object (i.e., JSON) and not null or undefined
    if (typeof ans === 'object' && !Array.isArray(ans)) {
      return (
        <div className="json-answer">
          {Object.keys(ans).map((key, index) => (
            <p key={index}>
              <strong>{key}:</strong>{' '}
              {Array.isArray(ans[key])
                ? ans[key].length === 0
                  ? 'No data'
                  : ans[key].join(', ')
                : ans[key] || 'No value'}
            </p>
          ))}
        </div>
      );
    }
  
    // If the answer is an array, iterate and render each item
    if (Array.isArray(ans)) {
      return ans.length > 0 ? (
        ans.map((item, index) => <div key={index}>{renderAnswer(item)}</div>)
      ) : (
        <p>No data available</p>
      );
    }
  
    // For plain text or any other primitive value
    return <p>{ans || 'No answer available'}</p>;
  };
  
  return (
    <div className="question-answer-container">
      <div className="question">{question || 'No question available'}</div>
      <div className="answer">
        {renderAnswer(answer)}
      </div>
    </div>
  );
};

export default QuestionAnswerComponent;
