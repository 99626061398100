import React, { useState, useEffect } from "react";
import axios from "axios";
import { LinkOutlined } from "@ant-design/icons";
import { Input, Button, Spin, notification } from "antd";
import config from "../../config";
import CollageShoppingComponent from "../cards/CollageShoppingComponent";
import "./ShoppingCollage.css";
import { load } from "cheerio";

const Links = ({ SelectUserData }) => {
  const [linkInputs, setLinkInputs] = useState(
    Array.from({ length: 10 }, () => ({ siteUrl: "", imageUrl: "" }))
  );
  const [highlightedIndexes, setHighlightedIndexes] = useState([]);
  const [product, setProduct] = useState([]);
  const [productImageLink, setProductImageLink] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBatch, setLoadingBatch] = useState(false);
  const [sampleImages, setSampleImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesData, setSelectedImagesData] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [preLinks, setPreLinks] = useState([]);

  const fetchInsertedData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${config.baseURL}getWebsiteSourceContent/${SelectUserData.user_id}/3`
      );

      if (response?.data?.recordset && response.data.recordset.length > 0) {
        const data = response.data.recordset[0];

        const selectedImagesList = JSON.parse(
          JSON.parse(data.SELECTED_IMAGES_DATA)
        );
        const sampleImagesList = JSON.parse(JSON.parse(data.SOURCE_CONTENT));
        console.log(
          data.SOURCE_CONTENT,
          JSON.parse(JSON.parse(data.SOURCE_CONTENT))
        );
        setSampleImages(sampleImagesList);
        setSelectedImages(selectedImagesList.map((item) => item.imageURL));
        setSelectedImagesData(selectedImagesList);
        setUploadedImages(selectedImagesList.map((item) => item.imageURL));
      } else {
        console.warn("No data found for the given user ID.");
        setSampleImages([]);
        setSelectedImages([]);
        setSelectedImagesData([]);
        setUploadedImages([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      notification.error({
        message: "Fetch Error",
        description: "There was an error fetching the inserted data.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInsertedData();
  }, []);

  const handleLinkChange = (index, field, e) => {
    const newLinkInputs = [...linkInputs];
    newLinkInputs[index] = {
      ...newLinkInputs[index],
      [field]: e.target.value,
    };
    setLinkInputs(newLinkInputs);
  };

  const RemoveBackground = async (items) => {
    setLoading(true);
    try {
      const response = await axios.post(`${config.baseURL}scrapeMyntra`, [
        items,
      ]);
      console.log(response.data); // Processed items with removed backgrounds

      if (response?.data) {
        setProduct((prev) => [...prev, ...response.data]);
        setProductImageLink((prev) => [...prev, ...response.data?.[0]?.url]);
        setSampleImages((prevSampleImages) => [
          ...prevSampleImages,
          ...response.data,
        ]);
      }
      console.log(null);
    } catch (err) {
      console.error("Error during scraping:", err);
      console.log("Error processing data.");
    } finally {
      setLoading(false);
    }
  };

  // const runScraper = async (url) => {
  //   console.log(url, ".....");

  //   try {
  //     // Send GET request using axios to fetch the webpage
  //     const response = await axios.get(url, {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Methods": "GET",
  //         Accept: "*/*",
  //       },
  //     });

  //     // Load the HTML response into cheerio for parsing
  //     const $ = load(response.data);

  //     // Find the script tag that contains 'application/ld+json'
  //     let productData = null;
  //     $('script[type="application/ld+json"]').each((i, script) => {
  //       const jsonData = JSON.parse($(script).html()); // Parse the JSON-LD data

  //       // Check if it contains the '@type' with 'Product'
  //       if (jsonData["@type"] === "Product") {
  //         productData = {
  //           name: jsonData.name,
  //           imageURL: jsonData.image,
  //           url: jsonData.offers?.url,
  //           price: jsonData.offers?.price,
  //           brand: "Myntra", // Set the brand to "Myntra" manually
  //         };
  //       }
  //     });
  //     console.log(productData, "productData...");

  //     if (Array.isArray([productData])) {
  //       await RemoveBackground(productData);
  //       // setProduct((prev) => [...prev, ...[productData]]);
  //       // setProductImageLink((prev) => [...prev, ...url]);
  //       // setSampleImages((prevSampleImages) => [
  //       //   ...prevSampleImages,
  //       //   ...[productData],
  //       // ]);
  //     } else {
  //       // handle the case where productData is not an array
  //       console.error("productData is not iterable:", productData);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching the URL: ", error);
  //     // setOutput(Error fetching the URL: ${error.message});
  //   }
  // };

  // const CreateCollageUseLink = async () => {
  //   setHighlightedIndexes([]);

  //   try {
  //     const filledLinks = linkInputs.filter(
  //       (link) => link.siteUrl && link.imageUrl
  //     );
  //     setPreLinks((prev) => [...prev, filledLinks]);
  //     console.log(filledLinks,linkInputs);
  //     if (linkInputs.length < 1) {
  //       notification.error({
  //         message: "Validation Error",
  //         description: "Please enter at least one valid link.",
  //       });
  //       setHighlightedIndexes(
  //         linkInputs
  //           .map((link, index) =>
  //             link.siteUrl === "" || link.imageUrl === "" ? index : null
  //           )
  //           .filter(Number.isInteger)
  //       );
  //       return;
  //     }

  //     setLoading(true);
  //     const urls = filledLinks.map((link) => link);
  //     // console.log(filledLinks, urls);
  //     const processBatch = async (batch) => {
  //       // console.log(filledLinks, urls, batch);
  //       const response = await axios.post(
  //         `${config.baseURL}scrape`,
  //         { urls: batch },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (response?.data) {
  //         const links = response.data.map((data) => data.imageURL);
  //         setProduct((prev) => [...prev, ...response.data]);
  //         setProductImageLink((prev) => [...prev, ...links]);
  //         setSampleImages((prevSampleImages) => [
  //           ...prevSampleImages,
  //           ...response.data,
  //         ]);
  //       }
  //     };

  //     await processBatch([urls[0]]);
  //     for (let i = 1; i < urls.length; i++) {
  //       const batch = urls.slice(i, i + 1);
  //       setLoadingBatch(true);
  //       await processBatch(batch);
  //       setLoadingBatch(false);
  //     }

  //     setLinkInputs(
  //       Array.from({ length: 10 }, () => ({ siteUrl: "", imageUrl: "" }))
  //     ); // Reset link inputs
  //     setHighlightedIndexes([]);
  //   } catch (error) {
  //     console.error("Error creating collage:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const runScraper = async (url) => {
    console.log(url, ".....");

    try {
      // Send GET request using fetch to fetch the webpage
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET",
          Accept: "*/*",
          "User-Agent":
            "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/85.0.4183.121 Safari/537.36",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.text(); // Get the response body as text

      // Load the HTML response into cheerio for parsing
      const $ = load(data);

      // Find the script tag that contains 'application/ld+json'
      let productData = null;
      $('script[type="application/ld+json"]').each((i, script) => {
        const jsonData = JSON.parse($(script).html()); // Parse the JSON-LD data

        // Check if it contains the '@type' with 'Product'
        if (jsonData["@type"] === "Product") {
          productData = {
            name: jsonData.name,
            imageURL: jsonData.image,
            url: jsonData.offers?.url,
            price: jsonData.offers?.price,
            brand: "Myntra", // Set the brand to "Myntra" manually
          };
        }
      });
      console.log(productData, "productData...");

      if (Array.isArray([productData])) {
        await RemoveBackground(productData);
        // setProduct((prev) => [...prev, ...[productData]]);
        // setProductImageLink((prev) => [...prev, ...url]);
        // setSampleImages((prevSampleImages) => [...prevSampleImages, ...[productData]]);
      } else {
        // handle the case where productData is not an array
        console.error("productData is not iterable:", productData);
      }
    } catch (error) {
      console.error("Error fetching the URL: ", error);
      // setOutput(`Error fetching the URL: ${error.message}`);
    }
  };

  // const runScraper = (url) => {
  //   console.log(url, ".....");

  //   // Send GET request using axios to fetch the webpage
  //   axios
  //     .get(url, {
  //       headers: {
  //         // Removed unsafe headers
  //         Accept: "*/*", // Accept header to specify the media types
  //       },
  //     })
  //     .then((response) => {
  //       // Load the HTML response into cheerio for parsing
  //       const $ = load(response.data);

  //       // Find the script tag that contains 'application/ld+json'
  //       let productData = null;
  //       $('script[type="application/ld+json"]').each((i, script) => {
  //         const jsonData = JSON.parse($(script).html()); // Parse the JSON-LD data

  //         // Check if it contains the '@type' with 'Product'
  //         if (jsonData["@type"] === "Product") {
  //           productData = {
  //             name: jsonData.name,
  //             imageURL: jsonData.image,
  //             url: jsonData.offers?.url,
  //             price: jsonData.offers?.price,
  //             brand: "Myntra", // Set the brand to "Myntra" manually
  //           };
  //         }
  //       });
  //       console.log(productData, "productData...");

  //       // Check if productData is an array or not
  //       if (Array.isArray([productData])) {
  //         return RemoveBackground(productData); // Return the promise for chaining
  //       } else {
  //         // Handle the case where productData is not an array
  //         console.error("productData is not iterable:", productData);
  //         return Promise.reject(new Error("productData is not iterable"));
  //       }
  //     })
  //     .then(() => {
  //       // Any additional logic after RemoveBackground can go here
  //       // e.g., updating state or handling the result
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the URL: ", error);
  //       // setOutput(`Error fetching the URL: ${error.message}`);
  //     });
  // };

  const CreateCollageUseLink = async () => {
    setHighlightedIndexes([]);

    try {
      // Filter out links with both siteUrl and imageUrl
      const filledLinks = linkInputs.filter(
        // (link) => link.siteUrl && link.imageUrl
        (link) => link.siteUrl
      );

      setPreLinks((prev) => [...prev, filledLinks]);

      // Log filled links and link inputs for debugging
      console.log(filledLinks, linkInputs);

      if (linkInputs.length < 1) {
        // Validation error for no links entered
        notification.error({
          message: "Validation Error",
          description: "Please enter at least one valid link.",
        });
        // Highlight missing siteUrl or imageUrl fields
        setHighlightedIndexes(
          linkInputs
            .map((link, index) =>
              link.siteUrl === "" || link.imageUrl === "" ? index : null
            )
            .filter(Number.isInteger)
        );
        return;
      }

      setLoading(true);

      // Separate Myntra links from the rest
      const myntraLinks = filledLinks.filter((link) =>
        link.siteUrl.toLowerCase().includes("myntra")
      );
      console.log(filledLinks);

      const otherLinks = filledLinks.filter(
        (link) => !link.siteUrl.toLowerCase().includes("myntra")
      );

      // Process a batch of URLs
      const processBatch = async (batch) => {
        const response = await axios.post(
          `${config.baseURL}scrape`,
          { urls: batch },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Log the response HTML code (or handle it as needed)
        console.log("HTML Response Object:", response.data);

        if (response?.data) {
          const links = response.data.map((data) => data.imageURL);
          // Update state with new product and image links
          setProduct((prev) => [...prev, ...response.data]);
          setProductImageLink((prev) => [...prev, ...links]);
          setSampleImages((prevSampleImages) => [
            ...prevSampleImages,
            ...response.data,
          ]);
        }
      };

      // First process Myntra links one by one
      for (let i = 0; i < myntraLinks.length; i++) {
        const myntraBatch = [myntraLinks[i]]; // One link at a time
        console.log("Processing Myntra link:", myntraBatch[0].siteUrl);
        // await processBatch(myntraBatch);
        await runScraper(myntraBatch[0].siteUrl);
      }

      // Process non-Myntra links
      for (let i = 0; i < otherLinks.length; i++) {
        const batch = [otherLinks[i]]; // One link at a time
        console.log("Processing other link:", batch[0].siteUrl);
        setLoadingBatch(true);
        await processBatch(batch);
        setLoadingBatch(false);
      }

      // Reset link inputs after processing
      setLinkInputs(
        Array.from({ length: 10 }, () => ({ siteUrl: "", imageUrl: "" }))
      );
      setHighlightedIndexes([]);
    } catch (error) {
      // Handle error
      console.error("Error creating collage:", error);
    } finally {
      // Set loading state to false when done
      setLoading(false);
    }
  };

  const toggleImageSelection = (imageURL) => {
    const image = imageURL.imageURL;

    const newSelectedImages = selectedImages.includes(image)
      ? selectedImages.filter((url) => url !== image)
      : [...selectedImages, image];
    setSelectedImages(newSelectedImages);

    const newSelectedImagesData = selectedImagesData.some(
      (data) => data.imageURL === image
    )
      ? selectedImagesData.filter((data) => data.imageURL !== image)
      : [...selectedImagesData, imageURL];
    setSelectedImagesData(newSelectedImagesData);
  };

  const handleSubmit = async () => {
    try {
      if (selectedImagesData.length < 2) {
        notification.error({
          message: "Minimum",
          description: "Please select at least two images to upload.",
        });
        return;
      }
      console.log(sampleImages);
      setLoading(true);
      const formData = new FormData();
      formData.append("CUSTOMER_ID", SelectUserData.user_id);
      formData.append("STYLIST_ID", SelectUserData.stylist_id);
      formData.append("SERVICE_ID", SelectUserData.SERVICE_ID);
      formData.append("USER_SERVICE_ID", SelectUserData.USER_SERVICE_ID);
      formData.append("LINK_LIST", JSON.stringify(preLinks));

      selectedImages.forEach((image) => {
        formData.append("IMAGE_LIST", image);
      });
      formData.append("SOURCE_CONTENT", JSON.stringify(sampleImages));
      formData.append(
        "SELECTED_IMAGES_DATA",
        JSON.stringify(selectedImagesData)
      );
      formData.append("type", "3");
      const uploadResponse = await axios.post(
        `${config.baseURL}websiteSourceContent`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (uploadResponse.status === 200) {
        notification.success({
          message: "Upload Successful",
          description: "Images have been uploaded successfully.",
        });

        setUploadedImages((prev) => [...prev, ...selectedImages]);
        setSelectedImages([]);
      } else {
        notification.error({
          message: "Upload Failed",
          description: "Failed to upload images. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      notification.error({
        message: "Upload Error",
        description: "There was an error uploading images.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="heading">Extracting Links</h1>
      <div className="Shopping-Collage-Wrapper">
        <p style={{ margin: "2% 2% 1% 2%" }}>
          Paste the links here to generate outfit images
        </p>
        <div className="Shopping-Collage-Grid">
          {linkInputs.map((link, index) => (
            <div
              key={index}
              className={`Shopping-Collage-Subcontent ${
                highlightedIndexes.includes(index) ? "error-container" : ""
              }`}
            >
              {" "}
              <div className="inSideLink">
                <LinkOutlined className="Scrapig-Linkoutlined" />
                <Input
                  className="Shopping-Collage-Input"
                  type="url"
                  value={link.siteUrl}
                  onChange={(e) => handleLinkChange(index, "siteUrl", e)} // Updated to handle specific input
                  placeholder="Enter Site URL"
                />{" "}
              </div>
              <div className="inSideLink">
                <LinkOutlined className="Scrapig-Linkoutlined" />
                <Input
                  className="Shopping-Collage-Input"
                  type="url"
                  value={link.imageUrl}
                  onChange={(e) => handleLinkChange(index, "imageUrl", e)} // Updated to handle specific input
                  placeholder="Enter Image Address"
                />
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="Shopping-Collage-Submit">
            <Button
              onClick={CreateCollageUseLink}
              disabled={loading}
              className="button"
            >
              Generate
            </Button>
          </div>
          <div className="underline-dotted"></div>
        </div>
        {sampleImages.length > 0 && (
          <p style={{ margin: "0% 2% 1% 2%" }}>
            Note: Choose the images below to add to your collage:
          </p>
        )}{" "}
        <div className="images-gallery">
          {sampleImages.map((product, index) => (
            <div
              key={index}
              style={{
                flex: "1",
                margin: "10px",
                cursor: "pointer",
                border: selectedImagesData.some(
                  (selectedItem) => selectedItem.imageURL === product.imageURL
                )
                  ? "2px solid blue" // Highlight uploaded images in blue
                  : "none",

                // border: selectedImages.includes(product.imageURL)
                //   ? "2px solid green" // Highlight selected images in green
                //   : uploadedImages.includes(product.imageURL)
                //   ? "2px solid blue" // Highlight uploaded images in blue
                //   : "none", // No border for unselected images
              }}
              onClick={() => toggleImageSelection(product)} // Toggle the selection of images
              className={`product-item ${
                selectedImages.includes(product.imageURL) ? "selected" : ""
              }`}
            >
              <CollageShoppingComponent
                brand={product.brand}
                imageURL={product.imageURL}
                name={product.name}
                price={product.price}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </div>
          ))}
        </div>
        <div className="images-gallery">{loading ? <Spin /> : <></>}</div>
      </div>

      <div className="Shopping-Collage-Submit">
        <Button type="" onClick={handleSubmit} className="button">
          Add
        </Button>
      </div>
    </>
  );
};

export default Links;
