// import React ,{useEffect} from 'react';
// import './css/Popup.css'; // Import your CSS file
// import axios from "axios";
// import config from "../../config";
// const Popup = ({ namesList, onClose ,onStylzClick}) => {

//   return (
//     <div className="popup-overlay">
//       <div className="popup-container">
//         <h2>Assigned To</h2>
//         <ul>
//           {namesList.map((item) => (
//             <li key={item.id} onClick={() => onStylzClick(item)}>
//               {item.FIRST_NAME}
//             </li>
//           ))}
//         </ul>
//         <button onClick={onClose}>Close</button>
//       </div>
//     </div>
//   );
// };

// export default Popup;

import React, { useState } from "react";
import "./css/Popup.css"; // Import your CSS file

const Popup = ({ namesList, onClose, onStylzClick }) => {
  const [selectedItem, setSelectedItem] = useState(null); // State to store selected name
  const [showConfirmation, setShowConfirmation] = useState(false); // State to toggle confirmation popup

  // Function to handle when a name is clicked
  const handleNameClick = (item) => {
    setSelectedItem(item); // Set the selected name
    setShowConfirmation(true); // Show confirmation popup
  };

  // Function to handle the confirmation click
  const handleConfirmClick = () => {
    onStylzClick(selectedItem); // Call the onStylzClick function with the selected item
    setShowConfirmation(false); // Hide the confirmation popup
    onClose(); // Close the main popup after confirming
  };

  // Function to handle canceling the confirmation
  const handleCancelClick = () => {
    setShowConfirmation(false); // Hide the confirmation popup
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="closebutton">
          <h2>Assigned To</h2>
          <div className="buttondiv">
            <button
              className="button"
              onClick={onClose}
              style={{ margin: "0" }}
            >
              &times; {/* Unicode character for "X" */}
            </button>
          </div>
        </div>

        {!showConfirmation ? (
          <div
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "68%"
            }}
          >
            <ul>
              {namesList.map((item) => (
                <li key={item.id} onClick={() => handleNameClick(item)}>
                  {item.FIRST_NAME}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          // Confirmation popup
          <div className="confirmation-popup">
            <p>Would you assign to {selectedItem?.FIRST_NAME}?</p>
            <div className="confirmation-buttons">
              <button
                onClick={handleCancelClick}
                className="button extrabutton"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmClick}
                className="button extrabutton"
              >
                Confirm
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;
