// // import React from 'react';
// // import "./css/UserDisplayCard.css"
// // import config from '../../config';
// // import Profile from '../../images/profile.jpg'
// // const UserDisplayCard = ({ image, name, dob,  buttonText,background,onClick }) => {
// //   return (
// //     <div className="user-display-card" style={{background:`${background}`}}>
// //       <div className="first-div">
// //         <div className="image-container">
// //         <img src={`${image ? `${config.imageUrl}${image}` : Profile}`} alt="User" />
// //         </div>
// //         <div className="info-container">
// //           <div className="info-item">
// //             {name}
// //           </div>
// //           <div className="button-container">
// //           <div className="info-itemdob">
// //            {dob}
// //           </div>
// //           <button onClick={onClick}>{buttonText}</button>
// //           </div>
// //         </div>

// //       </div>

// //     </div>
// //   );
// // };

// // export default UserDisplayCard;

// import React from "react";
// import "./css/UserDisplayCard.css";
// import config from "../../config";
// import Profile from "../../images/profile.jpg";
// const UserDisplayCard = ({
//   image,
//   name,
//   dob,
//   purchasedate,
//   category,
//   buttonText,
//   background,
//   onClick,
//   user,
// }) => {

//   function extractBaseUrl(url) {
//     // Split the URL by "https://" and filter out any empty parts
//     const parts = url.split("https://").filter(Boolean);

//     // Construct the base URL by joining the last part with "https://"
//     return `https://${parts[parts.length - 1]}`;
//   }
//   function formatDateToDDMMYY(isoString) {
//     const date = new Date(isoString);

//     console.log(user, "skdusauhdihk........");
//     // Get day, month, and year
//     const day = String(date.getDate()).padStart(2, "0"); // Ensures two digits
//     const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
//     const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

//     // Return formatted date
//     return `${day}/${month}/${year}`;
//   }
//   return (
//     <div className="user-display-card" style={{ background: `${background}` }}>
//       <div className="first-div">
//         <div className="image-container">
//           <img
//             src={`${image ? extractBaseUrl(`${config.imageUrl}${image}`) : Profile}`}
//             alt="User"
//           />
//         </div>
//         <div className="info-container">
//           <div className="info-item-container">
//             <div className="info-item">
//               {name}
//               <div className="info-itemdob">
//                 {purchasedate
//                   ? formatDateToDDMMYY(purchasedate)
//                   : user?.enrollment_date
//                   ? formatDateToDDMMYY(user?.enrollment_date)
//                   : ""}
//               </div>
//             </div>
//             <div>{category}</div>
//           </div>
//           <div className="button-container">
//             <button onClick={onClick}>{buttonText}</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserDisplayCard;

import React from "react";
import "./css/UserDisplayCard.css";
import config from "../../config";
import Profile from "../../images/profile.jpg";
import { Flex } from "antd";
const UserDisplayCard = ({
  image,
  name,
  dob,
  purchasedate,
  enddate,
  category,
  buttonText,
  background,
  onClick,
  user,
  stylistName,
}) => {
  function extractBaseUrl(url) {
    // Split the URL by "https://" and filter out any empty parts
    const parts = url.split("https://").filter(Boolean);

    // Construct the base URL by joining the last part with "https://"
    return `https://${parts[parts.length - 1]}`;
  }

  // const formatDate = (dateString) => {
  //   if (!dateString) return 'N/A'; // Handle case where dateString might be null or undefined

  //   const date = new Date(dateString);

  //   // Check if the date is valid
  //   if (isNaN(date.getTime())) return 'Invalid Date';

  //   // Array of month abbreviations
  //   const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  //   const day = date.getDate();
  //   const month = monthNames[date.getMonth()]; // Get the 3-letter month abbreviation
  //   const year = date.getFullYear();

  //   // Format the date as Day 3-Letter Month, Year
  //   return `${day < 10 ? '0' : ''}${day} ${month}, ${year}`;
  // };

  // const formatDate = (dateString) => {
  //   if (!dateString) return "N/A"; // Handle case where dateString might be null or undefined

  //   const date = new Date(dateString);

  //   // Check if the date is valid
  //   if (isNaN(date.getTime())) return "Invalid Date";

  //   // Array of month abbreviations
  //   const monthNames = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];

  //   const day = date.getDate();
  //   const month = monthNames[date.getMonth()]; // Get the 3-letter month abbreviation
  //   const year = date.getFullYear();

  //   // Get hours and minutes
  //   let hours = date.getHours();
  //   const minutes = date.getMinutes();

  //   // Determine AM or PM suffix
  //   const ampm = hours >= 12 ? "PM" : "AM";
  //   hours = hours % 12; // Convert to 12-hour format
  //   hours = hours ? hours : 12; // the hour '0' should be '12'

  //   // Format the time
  //   const formattedTime = `${hours}:${
  //     minutes < 10 ? "0" : ""
  //   }${minutes} ${ampm}`;

  //   // Format the date as Day 3-Letter Month, Year
  //   return `${day < 10 ? "0" : ""}${day} ${month}, ${year} ${formattedTime}`;
  // };
  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour time to 12-hour time format
    hours = hours % 12;
    hours = hours ? hours : 12; // handle 00 hour as 12 AM

    return `${day} ${month}, ${year} ${hours}:${minutes}${ampm}`;
  }
  // function formatDateToDDMMYY(isoString) {
  //   const date = new Date(isoString);
  //   console.log(user, "skdusauhdihk........");
  //   // Get day, month, and year
  //   const day = String(date.getDate()).padStart(2, "0"); // Ensures two digits
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  //   const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

  //   // Return formatted date
  //   return `${day}/${month}/${year}`;
  // }
  return (
    <div
      className="user-display-card"
      style={{
        background: `${background}`,
        // marginTop: `${stylistName ? "8%" : "5%"}`,
        // borderTopRightRadius: `${stylistName ? "0px" : "8px"}`,
      }}
    >
      <div className="first-div">
        <div className="image-container">
          <img
            src={`${
              image ? extractBaseUrl(`${config.imageUrl}${image}`) : Profile
            }`}
            alt="User"
          />
        </div>
        <div className="info-container">
          <div className="info-item-container">
            <div className="info-item">
              <Flex gap={20}>
                <p style={{ margin: "0" }}> {name ? name : "No Name"}</p>
                {user?.status === 4 && (
                  <p style={{ margin: "0" }}>
                    {" "}
                    {user?.gender ? user.gender : ""}
                  </p>
                )}
              </Flex>
              <Flex gap={20}>
                <div className="info-itemdob">
                  <div>Purchased Date:</div>
                  <div className="User-Purc-Date-Div">
                    {purchasedate
                      ? formatDate(purchasedate)
                      : user?.enrollment_date
                      ? formatDate(user?.enrollment_date)
                      : ""}
                  </div>
                </div>

                {user?.status === 4 && (
                  <div className="info-itemdob">
                    <div>Close Service :</div>
                    <div className="User-Purc-Date-Div">
                      {enddate
                        ? formatDate(enddate)
                        : user?.end_date
                        ? formatDate(user?.end_date)
                        : ""}
                    </div>
                  </div>
                )}
              </Flex>
            </div>
            <div>
              <p>{category ? category : ""}</p>
            </div>
          </div>
          <div className="button-container">
          {stylistName && (
              <div className="hovertodisplaydivm">
                <p
                  className="text"
                  style={{
                    background: `${background}`,
                  }}
                >
                Stylist : {stylistName ? stylistName : ""}
                </p>
              </div>
            )}
            <button onClick={onClick}>{buttonText}</button>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDisplayCard;
