// import React, { useState, useEffect } from "react";
// import "./css/UserWardrobe.css"; // Import the CSS file
// import config from "../../config";

// const UserWardrobe = ({ UserWardrobeImagesData }) => {
//   // Extract unique wear types from the data
//   const wearTypes = [
//     ...new Set(UserWardrobeImagesData.map((item) => item.WEAR_TYPE)),
//   ];

//   // Initialize state with the first wear type if available
//   const [selectedWearType, setSelectedWearType] = useState(
//     wearTypes.length > 0 ? wearTypes[0] : ""
//   );
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("All");

//   // Update categories when selectedWearType changes
//   useEffect(() => {
//     if (selectedWearType) {
//       const newCategories = [
//         "All",
//         ...new Set(
//           UserWardrobeImagesData.filter(
//             (item) => item.WEAR_TYPE === selectedWearType
//           ).map((item) => item.IMAGE_CATEGORY)
//         ),
//       ];
//       setCategories(newCategories);
//       setSelectedCategory("All"); // Reset category to "All" when changing wear type
//     }
//   }, [selectedWearType, UserWardrobeImagesData]);

//   // Filter data based on the selected wear type and category
//   const filteredData = UserWardrobeImagesData.filter((item) => {
//     if (selectedCategory === "All") {
//       return item.WEAR_TYPE === selectedWearType;
//     }
//     return (
//       item.WEAR_TYPE === selectedWearType &&
//       item.IMAGE_CATEGORY === selectedCategory
//     );
//   });

//   // Validate if wear types exist
//   if (wearTypes.length === 0) {
//     return <div>No data available</div>;
//   }

//   return (
//     <div className="user-wardrobe">
//       {/* Wear Type Tabs */}
//       <div className="wear-type-tabs">
//         {wearTypes.map((wearType) => (
//           <button
//             key={wearType}
//             onClick={() => setSelectedWearType(wearType)}
//             className={`button ${
//               wearType === selectedWearType ? "selected" : ""
//             }`}
//           >
//             {wearType}
//           </button>
//         ))}
//       </div>

//       {/* Category Tabs */}
//       <div className="category-tabs">
//         {categories.map((category) => (
//           <button
//             key={category}
//             onClick={() => setSelectedCategory(category)}
//             className={`button ${
//               category === selectedCategory ? "selected" : ""
//             }`}
//           >
//             {category}
//           </button>
//         ))}
//       </div>

//       {/* Display Images */}
//       <div className="image-containerWardrobe">
//         {filteredData.map((item, index) => (
//           <div key={index} className="image-item">
//             <img
//               src={`${config.imageUrl}/${item.IMAGE_NAME}`}
//               alt={item.IMAGE_CATEGORY}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default UserWardrobe;




// this is dynamic creation on tabs 


import React, { useState, useEffect } from "react";
import "./css/UserWardrobe.css"; // Import the CSS file
import config from "../../config";

const UserWardrobe = ({ UserWardrobeImagesData }) => {
  // Extract unique wear types from the data
  const wearTypes = [
    ...new Set(UserWardrobeImagesData.map((item) => item.WEAR_TYPE)),
  ];

  // Initialize state with the first wear type if available
  const [selectedWearType, setSelectedWearType] = useState(
    wearTypes.length > 0 ? wearTypes[0] : ""
  );
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [showUpload, setShowUpload] = useState(false); // State for showing upload form
  const [uploadedImages, setUploadedImages] = useState([]); // State for uploaded images

  // Update categories when selectedWearType changes
  useEffect(() => {
    if (selectedWearType) {
      const newCategories = [
        "All",
        ...new Set(
          UserWardrobeImagesData.filter(
            (item) => item.WEAR_TYPE === selectedWearType
          ).map((item) => item.IMAGE_CATEGORY)
        ),
      ];
      setCategories(newCategories);
      setSelectedCategory("All"); // Reset category to "All" when changing wear type
    }
  }, [selectedWearType, UserWardrobeImagesData]);

  // Filter data based on the selected wear type and category
  const filteredData = UserWardrobeImagesData.filter((item) => {
    if (selectedCategory === "All") {
      return item.WEAR_TYPE === selectedWearType;
    }
    return (
      item.WEAR_TYPE === selectedWearType &&
      item.IMAGE_CATEGORY === selectedCategory
    );
  });

  // Handle image upload
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImageUrls = files.map((file) => URL.createObjectURL(file));
    setUploadedImages((prevImages) => [...prevImages, ...newImageUrls]); // Add new images to the existing array
  };

  // Validate if wear types exist
  if (wearTypes.length === 0) {
    return (
      <div className="nodatafound">
        <p>No data available</p>
      </div>
    );
  }

  return (
    <div className="user-wardrobe">
      {/* Tabs */}
      <div className="wear-type-tabs">
        {wearTypes.map((wearType) => (
          <button
            key={wearType}
            onClick={() => {
              if (showUpload) {
                setShowUpload(false);
              }
              setSelectedWearType(wearType);
            }}
            className={`button ${
              wearType === selectedWearType ? "selected" : ""
            }`}
          >
            {wearType}
          </button>
        ))}
        {/* <button
          onClick={() => {
            setShowUpload(!showUpload); 
            setSelectedWearType(""); 
            setSelectedCategory("All"); 
          }}
          className={`button ${showUpload ? "selected" : ""}`}
        >
          Upload
        </button> */}
      </div>

      {/* Category Tabs */}
      {selectedWearType && !showUpload && (
        <div className="category-tabs">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`button ${
                category === selectedCategory ? "selected" : ""
              }`}
            >
              {category}
            </button>
          ))}
        </div>
      )}

      {/* Display Images */}
      {!showUpload && (
        <div className="image-containerWardrobe">
          {filteredData.map((item, index) => (
            <div key={index} className="image-item">
              <img
                src={`${config.imageUrl}/${item.IMAGE_NAME}`}
                alt={item.IMAGE_CATEGORY}
              />
            </div>
          ))}
        </div>
      )}

      {/* Upload Form */}
      {showUpload && (
        <div className="upload-form">
          <input
            type="file"
            accept="image/*"
            multiple // Allow multiple file uploads
            onChange={handleFileUpload}
          />
          <div className="image-containerWardrobe">
            {uploadedImages.map((imageUrl, index) => (
              <div key={index} className="image-item"
              onDragStart={(e) => {

                e.dataTransfer.setData("application/json", imageUrl);
              }}>
                <img src={imageUrl} alt={`Uploaded Preview ${index}`} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserWardrobe;


///


// import React, { useState, useEffect } from "react";
// import "./css/UserWardrobe.css"; // Import the CSS file
// import config from "../../config";

// const UserWardrobe = ({ UserWardrobeImagesData }) => {
//   // Define the static wear types and categories
//   const wearTypes = [
//     "Top Wear",
//     "Bottom Wear",
//     "Footwear",
//     "Accessories",
//     "Indian Wear",
//   ];

//   const categoriesByWearType = {
//     "Top Wear": ["Jacket", "Shirt", "T-shirt", "Hoodie"],
//     "Bottom Wear": ["Trousers", "Shorts"],
//     "Footwear": ["Shoes"],
//     "Accessories": ["Belt"],
//     "Indian Wear": ["Kurti"],
//   };

//   // Initialize state with the first wear type if available
//   const [selectedWearType, setSelectedWearType] = useState(wearTypes[0]);
//   const [selectedCategory, setSelectedCategory] = useState("All");
//   const [showUpload, setShowUpload] = useState(false); // State for showing upload form
//   const [uploadedImages, setUploadedImages] = useState([]); // State for uploaded images

//   // Update categories when selectedWearType changes
//   const categories =
//     selectedWearType && categoriesByWearType[selectedWearType]
//       ? ["All", ...categoriesByWearType[selectedWearType]]
//       : ["All"];

//   // Filter data based on the selected wear type and category
//   const filteredData = UserWardrobeImagesData.filter((item) => {
//     if (selectedCategory === "All") {
//       return item.WEAR_TYPE === selectedWearType;
//     }
//     return (
//       item.WEAR_TYPE === selectedWearType &&
//       item.IMAGE_CATEGORY === selectedCategory
//     );
//   });

//   // Handle image upload
//   const handleFileUpload = (event) => {
//     const files = Array.from(event.target.files);
//     const newImageUrls = files.map((file) => URL.createObjectURL(file));
//     setUploadedImages((prevImages) => [...prevImages, ...newImageUrls]); // Add new images to the existing array
//   };

//   return (
//     <div className="user-wardrobe">
//       {/* Wear Type Tabs */}
//       <div className="wear-type-tabs">
//         {wearTypes.map((wearType) => (
//           <button
//             key={wearType}
//             onClick={() => {
//               if (showUpload) {
//                 setShowUpload(false);
//               }
//               setSelectedWearType(wearType);
//               setSelectedCategory("All"); // Reset category when changing wear type
//             }}
//             className={`button ${
//               wearType === selectedWearType ? "selected" : ""
//             }`}
//           >
//             {wearType}
//           </button>
//         ))}
//         <button
//           onClick={() => {
//             setShowUpload(!showUpload); // Toggle upload form visibility
//             setSelectedWearType(""); // Optionally reset the selected wear type
//             setSelectedCategory("All"); // Reset the selected category
//           }}
//           className={`button ${showUpload ? "selected" : ""}`}
//         >
//           Upload
//         </button>
//       </div>

//       {/* Category Tabs */}
//       {selectedWearType && !showUpload && (
//         <div className="category-tabs">
//           {categories.map((category) => (
//             <button
//               key={category}
//               onClick={() => setSelectedCategory(category)}
//               className={`button ${
//                 category === selectedCategory ? "selected" : ""
//               }`}
//             >
//               {category}
//             </button>
//           ))}
//         </div>
//       )}

//       {/* Display Images or No Data Message */}
//       {!showUpload && (
//         <div className="image-containerWardrobe">
//           {filteredData.length > 0 ? (
//             filteredData.map((item, index) => (
//               <div key={index} className="image-item">
//                 <img
//                   src={`${config.imageUrl}/${item.IMAGE_NAME}`}
//                   alt={item.IMAGE_CATEGORY}
//                 />
//               </div>
//             ))
//           ) : (
//             <div className="nodatafound">
//               <p>No images available</p>
//             </div>
//           )}
//         </div>
//       )}

//       {/* Upload Form */}
//       {showUpload && (
//         <div className="upload-form">
//           <input
//             type="file"
//             accept="image/*"
//             multiple // Allow multiple file uploads
//             onChange={handleFileUpload}
//           />
//           <div className="image-containerWardrobe">
//             {uploadedImages.map((imageUrl, index) => (
//               <div key={index} className="image-item">
//                 <img src={imageUrl} alt={`Uploaded Preview ${index}`} />
//               </div>
//             ))}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserWardrobe;
