import React, { useState } from "react";
import axios from "axios";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import config from "../../config";
import { useNavigate } from "react-router-dom";

import { Button, message, notification, Space } from "antd";

const LoginForm = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const fcmToken = sessionStorage.getItem("fcmToken");
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, msg) => {
    api[type]({
      message: "Notification Title",
      description: msg,
    });
  };
 
  const signInMobile = async (mobileNumber, password, fcmToken) => {
    console.log(mobileNumber, password, fcmToken);
    try {
      const response = await axios.post(
        `${config.baseURL}websigninmobile`,
        new URLSearchParams({
          MOBILE_NUMBER: mobileNumber,
          PASSWORD: password,
          // ROLE: role,
          fcm_token: fcmToken,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response.data);
      // Handle the response as needed
      if (
        response?.data?.message === "Login successful" ||
        response?.status === 200
      ) {
        // console.log("login successfully", response?.data?.data?.[0], response?.data?.data?.[0]?.ROLE === "0");
        if (
          (response?.data?.data?.[0]?.ROLE === "0" ||
          response?.data?.data?.[0]?.ROLE === "2")&& response?.data?.data?.[0]?.STATUS !== "6"
        ) {
          console.log("login successfully", response?.data?.data?.[0]);
          
        openNotificationWithIcon("success", "login successfully. ");  
          sessionStorage.setItem(
            "LoginUser",
            JSON.stringify(response?.data?.data?.[0])
          );

          navigate("/dashboard");
        } else {
          message.error("This user does not have access to log in");
        }
      }

      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
      message.error(`${error?.response?.data?.error?.message}`)
    }
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    // if ((mobileNumber === "9999999999") & (password === "1234")) {
    //   try {
    //     const data = await signInMobile(mobileNumber, password, "0", fcmToken);
    //   } catch (error) {}
    // } else if ((mobileNumber === "1111111111") & (password === "1234")) {
    //   try {
    //     const data = await signInMobile(mobileNumber, password, "2", fcmToken);
    //   } catch (error) {}
    // } else {
    //   openNotificationWithIcon("error", "Invalid credentials");
    // }
    await signInMobile(mobileNumber, password,fcmToken);
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    // Allow only digits and limit to 10 characters
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-form-container">
      {contextHolder}
      <h2 className="login-heading">Login to your account</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="mobileNumber">Mobile number</label>
          <input
            type="text"
            id="mobileNumber"
            value={mobileNumber}
            onChange={handleMobileNumberChange}
            required
            placeholder="Enter your mobile number"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <div className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
            />
            <span
              className={`password-toggle-icon ${
                showPassword ? "visible" : ""
              }`}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </span>
          </div>
        </div>
        {/* <div className="forgot-password">
          <a href="/forgot-password">Forgot Password?</a>
        </div> */}
        <div className="form-group">
          <button type="submit" className="login-button">
            Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
