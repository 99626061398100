// import React, { useState } from 'react';
// import { Input, Button, Form, Row, Col, Select, Flex } from 'antd';
// import './manageuser.css'

// const { Option } = Select;
// const MyForm = () => {
//   const [formData, setFormData] = useState({
//     firstname: '',
//     lastname: '',
//     mobilenumber: '',
//     email: '',
//     Weight: '',
//     height: '',
//     DuplicateName: '',
//     password: '',
//     gender: '',
//   });

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSelectChange = (value) => {
//     setFormData({
//       ...formData,
//       gender: value,
//     });
//   };

//   const handleSubmit = () => {
//     console.log(formData);
//   };
//   return (
//     <Form layout="vertical" onFinish={handleSubmit} className='Manageuser-Content'>
//       <Flex gap={90}  gutter={16}>
//         <Col span={11}>
//           <Form.Item label="First Name" className='Manageuser-FormItem'
//             name="First Name"
//             rules={[{ required: true, message: 'Please enter your First Name' }]}
//           >
//             <Input
//               name="firstname"
//                className='Manageuser-Form-Input'
//               value={formData.firstname}
//               onChange={handleInputChange}
//             />
//           </Form.Item>
//         </Col>

//         <Col span={11}>
//           <Form.Item label="Last Name" className='Manageuser-FormItem'
//             name="Last Name"
//             rules={[{ required: true, message: 'Please enter your Last Name' }]}
//           >
//             <Input
//               className='Manageuser-Form-Input'
//               name="lastname"
//               value={formData.lastname}
//               onChange={handleInputChange}
//             />
//           </Form.Item>
//         </Col>
//       </Flex>

//       <Flex gap={90} gutter={16}>
//              <Col span={11}>
//           <Form.Item
//             className='Profile-FormItem'
//             label="Duplicate Name"
//             name="age"
//             rules={[{ required: true, message: 'Please enter your age' }]}
//           >
//             <Input
//               className='Manageuser-Form-Input'
//               name="Duplicate Name"
//               value={formData.age}
//               onChange={handleInputChange}
//             />
//           </Form.Item>
//         </Col>
//         <Col span={11}>
//           <Form.Item label="Mobile Number" className='Manageuser-FormItem'
//             name="Mobile Number"
//             rules={[{ required: true, message: 'Please enter your Mobile Number' }]}
//           >
//             <Input
//               className='Manageuser-Form-Input'
//               name="mobilenumber"
//               value={formData.mobilenumber}
//               onChange={handleInputChange}
//             />
//           </Form.Item>
//         </Col>

//       </Flex>

//       <Flex gap={90} gutter={16}>

//       <Col span={11}>
//           <Form.Item label="Email" className='Manageuser-FormItem'
//            name="Email"
//            rules={[{ required: true, message: 'Please enter your Email' }]}
//           >
//             <Input
//               className='Manageuser-Form-Input'
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//             />
//           </Form.Item>
//         </Col>

//         <Col span={11}>
//           <Form.Item
//             className='Manageuser-FormItem'
//             label="Password"
//             name="password"
//             rules={[{ required: true, message: 'Please enter your password' }]}
//           >
//             <Input.Password
//               className='Manageuser-Form-Input'
//               name="password"
//               value={formData.password}
//               onChange={handleInputChange}
//             />
//           </Form.Item>
//         </Col>
//       </Flex>

//       <Flex gutter={16}>
//         <Col span={11}>
//           <Form.Item

//             className='Manageuser-FormItem'
//             label="Gender"
//             name="gender"
//             rules={[{  required: true, message: 'Please select your gender' }]}
//           >
//             <Select
//               value={formData.gender}
//               onChange={handleSelectChange}
//               placeholder="Select your gender"
//             >
//               <Option value="male">Male</Option>
//               <Option value="female">Female</Option>
//               <Option value="other">Other</Option>
//             </Select>
//           </Form.Item>
//         </Col>
//       </Flex>

//       <Form.Item className='Manageuser-FormItem'>
//         <Button  htmlType="submit">
//           Submit
//         </Button>
//       </Form.Item>
//     </Form>
//   );
// };

// export default MyForm;

// import React, { useState, useEffect } from 'react';
// import { Input, Button, Form, Row, Col, Select, Modal, Table, message } from 'antd';
// import axios from 'axios';
// import './manageuser.css';
// import config from "../../config";

// const { Option } = Select;

// const Manageuser = () => {
//   const [formData, setFormData] = useState({
//     FIRST_NAME: '',
//     LAST_NAME: '',
//     MOBILE_NUMBER: '',
//     EMAIL: '',
//     WEIGHT: '',
//     HEIGHT: '',
//     DUPLICATE_FIRST_NAME: '',
//     PASSWORD: '',
//     GENDER: '',
//   });

//   const [fcmToken, setFcmToken] = useState('');
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isEditing, setIsEditing] = useState(false); // For tracking if we're in edit mode
//   const [recordToEdit, setRecordToEdit] = useState(null); // Record being edited
//   const [data, setData] = useState([]); // User data
//   const [form] = Form.useForm();

//   useEffect(() => {
//     const fetchFcmToken = async () => {
//       try {
//         const token = await config.getToken();
//         setFcmToken(token);
//       } catch (error) {
//         console.error('Error getting FCM token:', error);
//       }
//     };

//     fetchFcmToken();
//   }, []);
//   const fetchUserData = async () => {
//     try {
//       const response = await axios.get(`${config.baseURL}getStylistList`);
//       console.log(response.data.recordset, 'reeeeeeee..........')
//       setData(response.data.recordset);
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//     }
//   };
//   useEffect(() => {

//     fetchUserData();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSelectChange = (value) => {
//     setFormData({
//       ...formData,
//       GENDER: value,
//     });
//   };

//   const handleSubmit = async () => {
//     try {
//       await form.validateFields();
//       if (isEditing) {
//         // Edit logic
//         const response = await axios.post(`${config.baseURL}updateStylistDetails`, {
//           ...formData,
//           fcm_token: fcmToken,
//           ROLE: "2",
//           device_id: formData.MOBILE_NUMBER,
//         });
//         config.success('Record updated successfully');
//         console.log('Record updated successfully:', response.data);
//         // Update the record in the data state
//         setData(data.map(record => record.id === response.data.id ? response.data : record));
//       } else {
//         // Add logic
//         const response = await axios.post(`${config.baseURL}stylistRegistration`, {
//           ...formData,
//           fcm_token: fcmToken,
//           ROLE: "2",
//           device_id: formData.MOBILE_NUMBER,
//         }, {
//           headers: {
//             'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
//           }
//         });

//         // config.success('Form submitted successfully');
//         console.log('Form submitted successfully:', response.data);
//         if (response.data.message === "Success") {
//           message.success('Form submitted successfully');
//           await fetchUserData();
//           handleCancel();
//           setFormData({
//             FIRST_NAME: '',
//             LAST_NAME: '',
//             MOBILE_NUMBER: '',
//             EMAIL: '',
//             WEIGHT: '',
//             HEIGHT: '',
//             DUPLICATE_FIRST_NAME: '',
//             PASSWORD: '',
//             GENDER: '',
//           })
//         }
//         // // Add new record to table
//         // setData(response.data.records);
//       }
//       // handleCancel();
//     } catch (error) {
//       console.error('There was an error submitting the form:', error);
//       config.error('Error submitting the form');
//     }
//   };

//   const handleAddUser = () => {
//     setIsEditing(false);
//     setFormData({
//       FIRST_NAME: '',
//       LAST_NAME: '',
//       MOBILE_NUMBER: '',
//       EMAIL: '',
//       WEIGHT: '',
//       HEIGHT: '',
//       DUPLICATE_NAME: '',
//       PASSWORD: '',
//       GENDER: '',
//     });
//     setIsModalVisible(true);
//   };
//   const updateFormDataAndShowModal = (record) => {
//     setFormData({
//       FIRST_NAME: record.FIRST_NAME,
//       LAST_NAME: '',
//       MOBILE_NUMBER: '',
//       EMAIL: '',
//       WEIGHT: '',
//       HEIGHT: '',
//       DUPLICATE_FIRST_NAME: record?.DUPLICATE_FIRST_NAME,
//       PASSWORD: '',
//       GENDER: '',
//     });
//   };
//   // const handleEditUser = async(record) => {
//   //   console.log(record,'gfvhjjhbjjhv')
//   //   setIsEditing(true);
//   //   setRecordToEdit(record);
//   //   // setFormData({
//   //   //   FIRST_NAME: record.FIRST_NAME,
//   //   //   LAST_NAME: '',
//   //   //   MOBILE_NUMBER: '',
//   //   //   EMAIL: '',
//   //   //   WEIGHT: '',
//   //   //   HEIGHT: '',
//   //   //   DUPLICATE_FIRST_NAME: record?.DUPLICATE_FIRST_NAME,
//   //   //   PASSWORD: '',
//   //   //   GENDER: '',
//   //   // })
//   //  await updateFormDataAndShowModal(record)
//   //   setIsModalVisible(true);
//   // };

//   const handleEditUser = async (record) => {
//     console.log(record, 'gfvhjjhbjjhv');
//     setIsEditing(true);
//     setRecordToEdit(record);

//     // Use a callback to ensure the state update is completed
//     updateFormDataAndShowModal(record);

//     // Wait for the formData update to be processed if necessary
//     setTimeout(() => {
//       setIsModalVisible(true);
//     }, 0);  // This timeout ensures the modal is shown after the state update
//   };

//   const handleDeactivateUser = async (record,status) => {
//     console.log(record,"delete.....")
//     // when the 0 is Active to user 6 is to Inactive
//     try {
//       const response = await axios.post(`${config.baseURL}deleteStylist`, {
//         STATUS:status,
//         USER_ID: record.USER_ID,
//       });
//        console.log(response,'response.....RD')
//       if (response) {  // Assuming the response contains a "success" field
//         message.success('Record deactivated successfully');

//         fetchUserData();
//         // setIsModalVisible(false);
//       } else {
//         message.error('Error deactivating the record');
//       }
//     } catch (error) {
//       console.error('There was an error deactivating the record:', error);
//       message.error('Error deactivating the record');
//     }
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   const columns = [
//     {
//       title: 'First Name',
//       dataIndex: 'FIRST_NAME',
//       key: 'FIRST_NAME',
//     },
//     {
//       title: 'Last Name',
//       dataIndex: 'LAST_NAME',
//       key: 'LAST_NAME',
//     },
//     {
//       title: 'Duplicate Name',
//       dataIndex: 'DUPLICATE_FIRST_NAME',
//       key: 'DUPLICATE_FIRST_NAME',
//     },
//     {
//       title: 'Mobile Number',
//       dataIndex: 'MOBILE_NUMBER',
//       key: 'MOBILE_NUMBER',
//     },
//     {
//       title: 'Email',
//       dataIndex: 'EMAIL',
//       key: 'EMAIL',
//     },
//     // {
//     //   title: 'Password',
//     //   dataIndex: 'PASSWORD',
//     //   key: 'PASSWORD',
//     // },
//     {
//       title: 'Gender',
//       dataIndex: 'GENDER',
//       key: 'GENDER',
//     },
//     {
//       title: 'Status',
//       dataIndex: 'STATUS',
//       key: 'STATUS',
//       render: (text) => {
//         if (text === '6') return 'Inactive';
//         if (text === '0' || text === '1') return 'Active';
//         return text; // Fallback if there are other statuses
//       },
//     },
//     {
//       title: 'Action',
//       key: 'Action',
//       render: (text, record) => (
//         <div>
//           {record.STATUS !== '6' && (
//             <>
//               <Button onClick={() => handleEditUser(record)} style={{ marginRight: 8 }}>Edit</Button>
//               <Button onClick={() => handleDeactivateUser(record,6)} type="default">Deactivate</Button>
//             </>
//           )}
//           {record.STATUS === '6' && (
//             <>
//               <Button onClick={() => handleDeactivateUser(record,0)} type="default">activate</Button>
//             </>
//           )}
//         </div>
//       ),
//     },

//   ];

//   return (
//     <>
//       <Button type="primary" onClick={handleAddUser}>
//         Add User
//       </Button>

//       <Table dataSource={data} columns={columns} rowKey="id" />

//       <Modal
//         title={isEditing ? 'Edit User' : 'Add User'}
//         visible={isModalVisible}
//         onOk={handleSubmit}
//         onCancel={handleCancel}
//       >
//         {console.log(formData,'formData..........')}
//         <Form layout="vertical" form={form}  initialValues={formData}>
//           <Row gutter={16}>
//             <Col span={11}>
//               <Form.Item
//                 label="First Name"
//                 className="Manageuser-FormItem"
//                 name="First Name"
//                 rules={[{ required: true, message: 'Please enter your First Name' }]}
//               >
//                 <Input
//                   name="FIRST_NAME"
//                   className="Manageuser-Form-Input"
//                   value={formData.FIRST_NAME}
//                   onChange={handleInputChange}
//                 />
//               </Form.Item>
//             </Col>

//             <Col span={11}>
//               <Form.Item
//                 label="Last Name"
//                 className="Manageuser-FormItem"
//                 name="Last Name"
//                 rules={[{ required: true, message: 'Please enter your Last Name' }]}
//               >
//                 <Input
//                   className="Manageuser-Form-Input"
//                   name="LAST_NAME"
//                   value={formData.LAST_NAME}
//                   onChange={handleInputChange}
//                 />
//               </Form.Item>
//             </Col>
//           </Row>

//           <Row gutter={16}>
//             <Col span={11}>
//               <Form.Item
//                 className="Profile-FormItem"
//                 label="Duplicate Name"
//                 name="DUPLICATE_FIRST_NAME"
//                 rules={[{ required: true, message: 'Please enter a duplicate name' }]}
//               >
//                 <Input
//                   className="Manageuser-Form-Input"
//                   name="DUPLICATE_FIRST_NAME"
//                   value={formData.DUPLICATE_FIRST_NAME}
//                   onChange={handleInputChange}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={11}>
//               <Form.Item
//                 label="Mobile Number"
//                 className="Manageuser-FormItem"
//                 name="Mobile Number"
//                 rules={[
//                   { required: true, message: 'Please enter your Mobile Number' },
//                   {
//                     pattern: /^\d{10}$/,
//                     message: 'Mobile number must be exactly 10 digits',
//                   },
//                 ]}
//               >
//                 <Input
//                   className="Manageuser-Form-Input"
//                   name="MOBILE_NUMBER"
//                   value={formData.MOBILE_NUMBER}
//                   onChange={handleInputChange}
//                   maxLength={10}
//                 />
//               </Form.Item>
//             </Col>
//           </Row>

//           <Row gutter={16}>
//             <Col span={11}>
//               <Form.Item
//                 label="Email"
//                 className="Manageuser-FormItem"
//                 name="Email"
//                 rules={[
//                   { required: true, message: 'Please enter your Email' },
//                   {
//                     type: 'email',
//                     message: 'Please enter a valid Email address',
//                   },
//                 ]}
//               >
//                 <Input
//                   className="Manageuser-Form-Input"
//                   name="EMAIL"
//                   value={formData.EMAIL}
//                   onChange={handleInputChange}
//                 />
//               </Form.Item>
//             </Col>

//             <Col span={11}>
//               <Form.Item
//                 className="Manageuser-FormItem"
//                 label="Password"
//                 name="password"
//                 rules={[{ required: true, message: 'Please enter your password' }]}
//               >
//                 <Input.Password
//                   className="Manageuser-Form-Input"
//                   name="PASSWORD"
//                   value={formData.PASSWORD}
//                   onChange={handleInputChange}
//                 />
//               </Form.Item>
//             </Col>
//           </Row>

//           <Row gutter={16}>
//             <Col span={11}>
//               <Form.Item
//                 className="Manageuser-FormItem"
//                 label="Gender"
//                 name="GENDER"
//                 rules={[{ required: true, message: 'Please select your gender' }]}
//               >
//                 <Select
//                   value={formData.GENDER}
//                   onChange={handleSelectChange}
//                   placeholder="Select your gender"
//                 >
//                   <Option value="male">MALE</Option>
//                   <Option value="female">FEMALE</Option>
//                   <Option value="other">OTHER</Option>
//                 </Select>
//               </Form.Item>
//             </Col>
//           </Row>
//         </Form>
//       </Modal>
//     </>
//   );
// };

// export default Manageuser;
import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Form,
  Row,
  Col,
  Select,
  Modal,
  Table,
  message,
  Popover,
} from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import axios from "axios";
import "./manageuser.css";
import config from "../../config";

const { Option } = Select;

const Manageuser = () => {
  const [formData, setFormData] = useState({
    FIRST_NAME: "",
    LAST_NAME: "",
    MOBILE_NUMBER: "",
    EMAIL: "",
    WEIGHT: "",
    HEIGHT: "",
    DUPLICATE_FIRST_NAME: "",
    PASSWORD: "",
    GENDER: "",
  });

  const [fcmToken, setFcmToken] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // For tracking if we're in edit mode
  const [recordToEdit, setRecordToEdit] = useState(null); // Record being edited
  const [data, setData] = useState([]); // User data
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchFcmToken = async () => {
      try {
        const token = await config.getToken();
        setFcmToken(token);
      } catch (error) {
        console.error("Error getting FCM token:", error);
      }
    };

    fetchFcmToken();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${config.baseURL}getStylistList`);
      console.log(response.data.recordset, "reeeeeeee..........");
      setData(response.data.recordset);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    form.setFieldsValue(formData); // Update form fields whenever formData changes
  }, [formData, form]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Allow only letters in FIRST_NAME and LAST_NAME fields
    if (name === "FIRST_NAME" || name === "LAST_NAME") {
      const filteredValue = value.replace(/[^A-Za-z]/g, ""); // Replace non-letter characters
      setFormData({
        ...formData,
        [name]: filteredValue,
      });
    } else if (name === "MOBILE_NUMBER") {
      const filteredValue = value.replace(/\D/g, ""); // Remove non-digit characters for MOBILE_NUMBER
      setFormData({
        ...formData,
        [name]: filteredValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (value) => {
    setFormData({
      ...formData,
      GENDER: value,
    });
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      if (isEditing) {
        // Edit logic
        const response = await axios.post(
          `${config.baseURL}updateStylistDetails`,
          {
            // ...formData,
            // fcm_token: fcmToken,
            // ROLE: "2",

            ...formData,

            fcm_token: fcmToken,
            ROLE: "2",
            device_id: formData.MOBILE_NUMBER,
            USER_ID: recordToEdit.USER_ID,
          }
        );
        if (response.status === 201) fetchUserData();
        {
          message.success("Record updated successfully");
          console.log("Record updated successfully:", response.data);
          handleCancel();
          // Update the record in the data state
          // setData(data.map(record => record.id === response.data.id ? response.data : record));
        }
      } else {
        // Add logic
        const response = await axios.post(
          `${config.baseURL}stylistRegistration`,
          {
            ...formData,

            fcm_token: fcmToken,
            ROLE: "2",
            device_id: formData.MOBILE_NUMBER,
          },
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        );

        // config.success('Form submitted successfully');
        console.log("Added User successfully:", response.data);
        if (response.data.message === "Success") {
          message.success("User Added successfully");
          await fetchUserData();
          handleCancel();
          setFormData({
            FIRST_NAME: "",
            LAST_NAME: "",
            MOBILE_NUMBER: "",
            EMAIL: "",
            WEIGHT: "",
            HEIGHT: "",
            DUPLICATE_FIRST_NAME: "",
            PASSWORD: "",
            GENDER: "",
          });
        }
        // // Add new record to table
        // setData(response.data.records);
      }
      // handleCancel();
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      // message.error('Error submitting the form');
    }
  };

  const handleAddUser = () => {
    setIsEditing(false);
    setFormData({
      FIRST_NAME: "",
      LAST_NAME: "",
      MOBILE_NUMBER: "",
      EMAIL: "",
      WEIGHT: "",
      HEIGHT: "",
      DUPLICATE_FIRST_NAME: "",
      PASSWORD: "",
      GENDER: "",
    });
    setIsModalVisible(true);
  };

  const updateFormDataAndShowModal = (record) => {
    setFormData({
      FIRST_NAME: record.FIRST_NAME,
      LAST_NAME: record.LAST_NAME,
      MOBILE_NUMBER: record.MOBILE_NUMBER,
      EMAIL: record.EMAIL,
      WEIGHT: "",
      HEIGHT: "",
      DUPLICATE_FIRST_NAME: record?.DUPLICATE_FIRST_NAME,
      PASSWORD: record.PASSWORD,
      GENDER: record.GENDER,
    });
  };

  const handleEditUser = async (record) => {
    console.log(record, "gfvhjjhbjjhv");
    setIsEditing(true);
    setRecordToEdit(record);

    // Use a callback to ensure the state update is completed
    updateFormDataAndShowModal(record);

    // Wait for the formData update to be processed if necessary
    setTimeout(() => {
      setIsModalVisible(true);
    }, 0); // This timeout ensures the modal is shown after the state update
  };

  const handleDeactivateUser = async (record, status) => {
    console.log(record, "delete.....");

    // when the 0 is Active to user 6 is to Inactive
    try {
      const response = await axios.post(`${config.baseURL}deleteStylist`, {
        STATUS: status,
        USER_ID: record.USER_ID,
      });
      console.log(response, "response.....RD");
      if (response) {
        // Assuming the response contains a "success" field
        message.success(
          status === "6"
            ? "Record Deactivated successfully "
            : "Record Activated successfully"
        );

        fetchUserData();
      } else {
        message.error("Error deactivating the record");
      }
    } catch (error) {
      console.error("There was an error deactivating the record:", error);
      message.error("Error deactivating the record");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (_, __, index) => index + 1,
    },
    {
      title: "First Name",
      dataIndex: "FIRST_NAME",
      key: "FIRST_NAME",
    },
    {
      title: "Last Name",
      dataIndex: "LAST_NAME",
      key: "LAST_NAME",
    },
    {
      title: "Duplicate Name",
      dataIndex: "DUPLICATE_FIRST_NAME",
      key: "DUPLICATE_FIRST_NAME",
    },
    {
      title: "Mobile Number",
      dataIndex: "MOBILE_NUMBER",
      key: "MOBILE_NUMBER",
    },
    {
      title: "Email",
      dataIndex: "EMAIL",
      key: "EMAIL",
    },
    // {
    //   title: 'Password',
    //   dataIndex: 'PASSWORD',
    //   key: 'PASSWORD',
    // },
    {
      title: "Gender",
      dataIndex: "GENDER",
      key: "GENDER",
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'STATUS',
    //   key: 'STATUS',
    //   render: (text) => {
    //     if (text === '6') return 'Inactive';
    //     if (text === '0' || text === '1') return 'Active';
    //     return text; // Fallback if there are other statuses
    //   },
    // },
    {
      title: "Status",
      dataIndex: "STATUS",
      key: "STATUS",
      render: (text) => {
        let statusText = "";
        let color = "";

        if (text === "6") {
          statusText = "Inactive";
          color = "red"; // Inactive status color
        } else if (text === "0" || text === "1") {
          statusText = "Active";
          color = "green"; // Active status color
        } else {
          statusText = text; // Fallback if there are other statuses
          color = "default";
        }

        return <span style={{ color }}>{statusText}</span>;
      },
    },
    {
      title: "Action",
      key: "Action",
      render: (text, record) => (
        <div>
          {record.STATUS === "6" ? (
            <Popover placement="bottom" content="Active">
              <Button
                onClick={() => handleDeactivateUser(record, "0")}
                type="default"
              >
                <CheckCircleOutlined />
              </Button>
            </Popover>
          ) : (
            <>
              <Popover placement="bottom" content="Edit Record">
                <Button
                  onClick={() => handleEditUser(record)}
                  style={{ marginRight: 8 }}
                >
                  <EditOutlined />
                </Button>
              </Popover>
              <Popover placement="bottom" content="Deactive">
                <Button
                  onClick={() => handleDeactivateUser(record, "6")}
                  type="default"
                >
                  <CloseCircleOutlined />
                </Button>
              </Popover>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    // <>
    //   <div className='Manageuser-Header-Adduser'>
    //     <Button className='button-container button' onClick={handleAddUser}>
    //       Add User
    //     </Button>
    //   </div>
    //   <div className='Manageuser-Table'>

    //     <Table className='customTableHeader' dataSource={data} columns={columns} rowKey="id" scroll={{ x: '100%' }} />
    //   </div>

    //   <Modal
    //     title={isEditing ? 'Edit User' : 'Add User'}
    //     visible={isModalVisible}
    //     onOk={handleSubmit}
    //     onCancel={handleCancel}
    //   >
    //     {/* {console.log(formData,'form data in modal')} */}
    //     <Form
    //       form={form}
    //       layout="vertical"
    //       initialValues={formData} // Initial values are set here
    //     >
    //       <Row gutter={16}>
    //         <Col span={12}>
    //           <Form.Item
    //             label="First Name"
    //             name="FIRST_NAME"
    //             rules={[{ required: true, message: 'Please enter your first name' }]}
    //           >
    //             <Input name="FIRST_NAME" onChange={handleInputChange} />
    //           </Form.Item>
    //         </Col>
    //         <Col span={12}>
    //           <Form.Item
    //             label="Last Name"
    //             name="LAST_NAME"
    //             rules={[{ required: true, message: 'Please enter your last name' }]}
    //           >
    //             <Input name="LAST_NAME" onChange={handleInputChange} />
    //           </Form.Item>
    //         </Col>
    //       </Row>
    //       <Row gutter={16}>
    //         <Col span={12}>
    //           <Form.Item
    //             label="Duplicate Name"
    //             name="DUPLICATE_FIRST_NAME"
    //             rules={[{ required: true, message: 'Please enter your Duplicate Name' }]}
    //           >
    //             <Input name="DUPLICATE_FIRST_NAME" onChange={handleInputChange} />
    //           </Form.Item>
    //         </Col>
    //         <Col span={12}>
    //           <Form.Item
    //             label="Email"
    //             name="EMAIL"
    //             rules={[
    //               { required: true, message: 'Please enter your Email' },
    //               {
    //                 type: 'email',
    //                 message: 'Please enter a valid Email address',
    //               },
    //             ]}
    //           >
    //             <Input name="EMAIL" onChange={handleInputChange} />
    //           </Form.Item>
    //         </Col>
    //       </Row>
    //       <Row gutter={16}>
    //         <Col span={12}>
    //           <Form.Item
    //             label="Mobile Number"
    //             name="MOBILE_NUMBER"
    //             rules={[
    //               { required: true, message: 'Please enter your Mobile Number' },
    //               {
    //                 pattern: /^\d{10}$/,
    //                 message: 'Mobile number must be exactly 10 digits',
    //               },
    //             ]}
    //           >
    //             <Input name="MOBILE_NUMBER" onChange={handleInputChange}
    //               maxLength={10}
    //             />
    //           </Form.Item>
    //         </Col>
    //         <Col span={12}>
    //           <Form.Item
    //             label="Password"
    //             name="PASSWORD"
    //             rules={[{ required: true, message: 'Please enter your Password' }]}
    //           >
    //             <Input.Password  name="PASSWORD" onChange={handleInputChange} />
    //           </Form.Item>
    //         </Col>
    //       </Row>

    //       <Form.Item
    //         label="Gender"
    //         name="GENDER"
    //         rules={[{ required: true, message: 'Please select your gender' }]}
    //       >
    //         <Select onChange={handleSelectChange} value={formData.GENDER}>
    //           <Option value="Male">Male</Option>
    //           <Option value="Female">Female</Option>
    //           <Option value="Other">Other</Option>
    //         </Select>
    //       </Form.Item>
    //     </Form>
    //   </Modal>
    // </>
    <>
      <div className="Manageuser-Header-Adduser">
        <Button className="button-container button adduserbutton" onClick={handleAddUser}>
          Add User
        </Button>
      </div>
      <div className="Manageuser-Table">
        <Table
          className="customTableHeader"
          dataSource={data}
          columns={columns}
          rowKey="id"
          scroll={{ x: "100%" }}
        />
      </div>

      <Modal
        className="Manageuser-Add-Edit-Modal"
        title={isEditing ? "Edit User" : "Add User"}
        visible={isModalVisible}
        onOk={handleSubmit}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={formData} // Initial values are set here
          style={{ padding: "3%" }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="FIRST_NAME"
                rules={[
                  { required: true, message: "Please enter your first name" },
                  {
                    pattern: /^[A-Za-z]+$/, // Regex to allow only letters
                    message: "First name must contain only letters",
                  },
                ]}
              >
                <Input name="FIRST_NAME" onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="LAST_NAME"
                rules={[
                  { required: true, message: "Please enter your last name" },
                  {
                    pattern: /^[A-Za-z]+$/, // Regex to allow only letters
                    message: "Last name must contain only letters",
                  },
                ]}
              >
                <Input name="LAST_NAME" onChange={handleInputChange} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Duplicate Name"
                name="DUPLICATE_FIRST_NAME"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Duplicate Name",
                  },
                ]}
              >
                <Input
                  name="DUPLICATE_FIRST_NAME"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="EMAIL"
                rules={[
                  { required: true, message: "Please enter your Email" },
                  {
                    type: "email",
                    message: "Please enter a valid Email address",
                  },
                ]}
              >
                <Input name="EMAIL" onChange={handleInputChange} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Mobile Number"
                name="MOBILE_NUMBER"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Mobile Number",
                  },
                  {
                    pattern: /^\d{10}$/,
                    message: "Mobile number must be exactly 10 digits",
                  },
                ]}
              >
                <Input
                  name="MOBILE_NUMBER"
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </Form.Item>
            </Col>

            {!isEditing && ( // Conditionally render password field only if not editing
              <Col span={12}>
                <Form.Item
                  label="Password"
                  name="PASSWORD"
                  rules={[
                    { required: true, message: "Please enter your Password" },
                  ]}
                >
                  <Input.Password
                    name="PASSWORD"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Form.Item
            label="Gender"
            name="GENDER"
            rules={[{ required: true, message: "Please select your gender" }]}
          >
            <Select onChange={handleSelectChange} value={formData.GENDER}>
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Manageuser;
